const SWINDLER_GO_DIE = [
    '時刻繃緊防範之弦，謹防新型電信詐騙。',
    '號碼陌⽣勿輕接，虛擬電話設陷阱。',
    '飛來⼤獎莫驚喜，讓您掏錢洞⽆底。',
    '不存貪婪⼼，詐騙難得逞。',
    '提⾼防騙意識，增強防範能⼒，構築電信詐騙“防⽕墻。',
    '騙⼈之⼼不可有，防騙之⼼不可⽆。',
    '⽹上匯款需警惕，電話核實莫⼤意。',
    '執法辦案有規範，怎會匯款到個⼈。',
    '不明電話及時掛，可疑短信不要回。',
    '⽹絡購物便利多，⽀付流程要仔細。',
    '投資理財和股票，多是騙⼦設的套。',
    '不信陌⽣短信，拒接陌⽣來電，讓騙⼦⽆從下⼿。',
    '⼀不貪⼆不佔，詐騙再詭玩不轉。',
    '遇到恐嚇要淡定，說妳違法莫慌張，⼀旦難分真與假，警方電話110。',
    '陌⽣來電要提防，多⽅確認防上當。',
    '致富⼗年功，詐騙⼀場空。',
    '積極加強⾃我防範意識，共同提⾼識騙防騙能⼒。',
    '防範⽹絡的騙術，不貪便宜要記住。',
    ' 和諧⽹絡妳我共享，電信詐騙⼤家共防。',
    '真假⽹店難分辨，購物不慎就被騙。',
    '個⼈信息頂重要，密碼賬號保管好。',
    '飛來⼤獎莫驚喜，讓妳掏錢洞⽆底。',
    '安全賬戶⼦虛有，⼤額匯款要三思。',
    '異地刷卡消費現，不要著急忙給錢。',
    '電話通知接傳票，實為騙錢設圈套。',
    '刷卡消費莫離眼，防⽌盜刷盯著點。',
    '⼼中⽆貪念，騙局遠⾝邊。',
    '轉賬匯款須謹慎，萬元以上到櫃⾯。',
    '陌⽣電話勿輕信，對⽅⾝份要核清。',
    '電信詐騙不難防，不給不要不上當。',
    '陌⽣信息不要理，以防害⼈⼜害⼰。',
]

const EASEIM_HINT =
  ''

const WARM_TIP = ''
export default { SWINDLER_GO_DIE, EASEIM_HINT, WARM_TIP }
